
import { defineComponent, reactive } from 'vue';
import ServicoSistema from '@/servicos/MeuSistema/ServicoSistema';
import { IDadosLogin } from '../models/IDadosLogin';
import GerenteAutenticacao from '../gerentes/GerenteAutenticacao';
import QuadroLogin from './QuadroLogin.vue';
import storeSistema from '@/store/storeSistema';
import router from '@/router';
import CampoSenha from '../components/Tela/CampoSenha.vue';
import Icone from '../components/Icone.vue';

export default defineComponent({
  name: 'Login',
  components: {
    QuadroLogin,
    Icone,
    CampoSenha,
  },
  setup() {
    const gerenteAutenticacao = new GerenteAutenticacao();

    if (gerenteAutenticacao.existeToken()) {
      router.push({ name: 'MinhaDashboard' });
    }
    const state = reactive({
      dadosLogin: {} as IDadosLogin,
      mensagemAlerta: '',
    });

    async function efetuarLogin() {
      if (!state.dadosLogin.login) {
        state.mensagemAlerta = 'Informe o seu usuário ou e-mail!';
        return;
      }
      if (!state.dadosLogin.senha) {
        state.mensagemAlerta = 'Por favor, digite a sua senha!';
        return;
      }

      try {
        const retornoAutenticacao = await new ServicoSistema().login(state.dadosLogin);
        if (retornoAutenticacao.autenticado) {
          gerenteAutenticacao.salvarDadosAutenticacao(retornoAutenticacao.token, retornoAutenticacao.dataExpiracao.toString());
          router.push({ name: 'MinhaDashboard' });
        } else {
          state.mensagemAlerta = retornoAutenticacao.mensagem;
        }
      } catch (error) {
        state.mensagemAlerta = 'Desculpe-nos não conseguimos realizar o login no momento.';
      }
    }

    function redefinirSenha() {
      router.push({ name: 'EsqueciMinhaSenha' });
    }

    return {
      state,
      storeSistema,
      efetuarLogin,
      redefinirSenha,
    };
  },
});
